import React, { useState, useEffect } from "react";
import ResultsRow from "./ResultsRow";
import ResultsRowHeader from "../ResultsRowHeader/ResultsRowHeader";
import TextResultCard from "../TextResultCard/TextResultCard";
import * as Count from "../../utils/results-count";
import * as S from "./styles.common";

const WebResults = ({ results }) => {
  const [shouldExpand, setShouldExpand] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (Count.Web(results) > 6) {
      setShouldExpand(true);
    }
  }, [results]);

  return (
    <ResultsRow>
      <ResultsRowHeader
        type={`Web`}
        count={Count.Web(results)}
        shouldExpand={shouldExpand}
        isExpanded={isExpanded}
        handleToggle={handleExpandToggle}
      />
      {results && results.webResults && (
        <S.WebResults isExpanded={isExpanded}>
          {results.webResults.map((web) => (
            
            <TextResultCard
              result={{
                title: web.fields.title,
                text: web.highlights.content,
                source: web.fields.url,
              }}
              key={web.id}
            />
          ))}
        </S.WebResults>
      )}
    </ResultsRow>
  );
};

export default WebResults;
