import React, { useState, useEffect } from "react";
import ResultsRow from "./ResultsRow";
import ResultsRowHeader from "../ResultsRowHeader/ResultsRowHeader";
import DocumentResultsCard from "../DocumentResultCard/DocumentResultCard";
import * as Count from "../../utils/results-count";
import * as S from "./styles.common";

const DocResults = ({ results }) => {
  const [shouldExpand, setShouldExpand] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (Count.Documents(results) > 6) {
      setShouldExpand(true);
    }
  }, [results]);
  return (
    <ResultsRow>
      <ResultsRowHeader
        type={`Documents`}
        count={Count.Documents(results)}
        shouldExpand={shouldExpand}
        isExpanded={isExpanded}
        handleToggle={handleExpandToggle}
      />
      {results && results.docResults && (
        <S.DocResults isExpanded={isExpanded}>
          {results.docResults.map((doc) => (
            <DocumentResultsCard
              result={{
                title: doc.fields.title[0],
                type: doc.fields.media_type[0],
                summary: doc.highlights.content,
                source: doc.fields.url[0],
              }}
              key={doc.id}
            />
          ))}
        </S.DocResults>
      )}
    </ResultsRow>
  );
};

export default DocResults;
