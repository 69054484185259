import React from "react";
import PropTypes from "prop-types";
import ProfilePic from "../ProfilePic/ProfilePic";
import ContactSummary from "../ContactSummary/ContactSummary";
import * as S from "./ContactSummaryCard.styles";

const ContactSummaryCard = ({ contact,
  id,
  name,
  title,
  company,
  image,
  email,
  phones,
  addresses,
  owner,
  location,
  lastInteraction,
  relationship,
}) => {
  const link = `https://app.4degrees.ai/contact?id=${id}`;
  return (
    <S.Card>
      <S.RelationShip>
        <div style={{ fontSize: "10px", textAlign: "center" }}>
          Relationship Strength
        </div>
        <S.NumContainer>
          <S.RelationshipNum>{relationship}</S.RelationshipNum>
        </S.NumContainer>
      </S.RelationShip>
      <div style={{ padding: "5px" }}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <ProfilePic image={image} alt={`Picture of ${name}`} />
          <ContactSummary
            link={link}
            name={name}
            image={image}
            title={title}
            company={company}
            addresses={addresses}
            location={location}
            lastInteraction={lastInteraction}
            owner={owner}
          />
        </a>
      </div>
      <S.Row>
        <S.Detail>
          <div style={{ textAlign: "center" }}>
            Location
            <br />
            {location && location.length > 0 ? location : "Not Available"}
          </div>
          <div style={{ textAlign: "center" }}>
            Last Interaction
            <br />
            {lastInteraction}
          </div>
          <div style={{ textAlign: "center" }}>
            Primary Contact
            <br />
            {owner}
          </div>
        </S.Detail>
      </S.Row>
    </S.Card>
  );
};

ContactSummaryCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  image: PropTypes.string,
  phones: PropTypes.array,
  addresses: PropTypes.array,
  owner: PropTypes.string,
};

ContactSummaryCard.defaultProps = {};

export default ContactSummaryCard;
