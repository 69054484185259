import React from "react";
import PropTypes from "prop-types";
import RefineOption from "../RefineOption/RefineOption";
import * as S from "./DataSources.styles";

const DataSources = ({
  sources,
  handleDegreesToggle,
  handleBloomfireToggle,
  handleWebToggle
}) => {
  const { degrees, bloomfire, web } = sources;
  return (
    <S.Container>
      <S.Header>
        <S.Title>Data Sources</S.Title>
      </S.Header>
      <S.Options>
        <RefineOption
          handleToggle={handleDegreesToggle}
          label="Orion Relationships"
          selected={degrees}
        />
        <RefineOption
          handleToggle={handleBloomfireToggle}
          label="Orion Insights"
          selected={bloomfire}
        />
        <RefineOption
          handleToggle={handleWebToggle}
          label="Web"
          selected={web}
        />
          
      </S.Options>
    </S.Container>
  );
};

DataSources.propTypes = {
  sources: PropTypes.object,
  handleDegreesToggle: PropTypes.func,
  handleBloomfireToggle: PropTypes.func,
  handleWebToggle: PropTypes.func,
};

DataSources.defaultProps = {
  sources: {
    degrees: true,
    bloomfire: true,
    web: true,
  },
};

export default DataSources;
