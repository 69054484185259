import React, { useState, useEffect, useContext } from "react";
import Cookies from 'universal-cookie';
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import SearchContext from "../../SearchContext";
import UserContext from "../../UserContext";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";
import SecondarySearchInput from "../../components/SecondarySearchInput/SecondarySearchInput";
import AuthButton from "../../components/AuthButton/AuthButton";
import * as QueryString from "query-string";

import {
  WebResults,
  DocResults,
  ArticleResults,
  RelationshipResults,
  DealsResults
} from "../../components/Results";

import * as S from "./Results.styles";
const cookies = new Cookies();
const COOKIE_KEY = "_login_redirect";

const Results = ({ match, location }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const query = isLoaded ? {} : QueryString.parse(location.search);

  const {
    term,
    setTerm,
    onHandleSearch,
    setQueryParams,
    results,
    setResults,
    isLoading,
  } = useContext(SearchContext);
  const { auth, signin } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {

  function performSearch (term)
  {
      setTerm (term);

      if (!isLoaded)
      {
          query.search = "";
          history.replace ("/results");
          setIsLoaded (true);
          setDocumentsActive (false);
          setWebActive (false);
          setArticlesActive (false);
          setQueryParams ({}, term);
      }
  }

    var term = query.search;

    if (term && !isLoaded && ~window.location.href.indexOf ("?search"))
    {
        Auth.currentSession ()
            .then (function (res)
            {
                performSearch (term)
            })
            .catch (function (e)
            {
                if (!cookies.get (COOKIE_KEY))
                {
                    cookies.set (COOKIE_KEY, window.location.href);
                    signin ();
                }
            })
        ;
    }
    else
    // If the user isn't logged in, go back home
    if (auth === false)
    {
      setTerm("");
      setResults(null);
      history.push("/");
    }

  }, [auth, signin, history, setResults, setTerm, results, query, isLoaded, onHandleSearch, setQueryParams]);

  const onUpdateSearchTerm = (e) => setTerm(e.target.value);

  // Local category visible state
  const [refineSearchActive, setRefineSearchActive] = useState(false);
  const [relationshipsActive, setRelationshipsActive] = useState(true);
  const [dealsAndListsActive, setDealsAndListsActive] = useState(true);
  const [documentsActive, setDocumentsActive] = useState(true);
  const [webActive, setWebActive] = useState(true);
  const [articlesActive, setArticlesActive] = useState(true);


  return (
    <>
      <LoadingBar active={isLoading} />
      <S.Page>
        <S.Menu>
          <Menu
            refineSearchActive={refineSearchActive}
            relationshipsActive={relationshipsActive}
            dealsAndListsActive={dealsAndListsActive}
            documentsActive={documentsActive}
            webActive={webActive}
            articlesActive={articlesActive}
            setRefineSearchActive={setRefineSearchActive}
            setRelationshipsActive={setRelationshipsActive}
            setDealsAndListsActive={setDealsAndListsActive}
            setDocumentsActive={setDocumentsActive}
            setWebActive={setWebActive}
            setArticlesActive={setArticlesActive}
          />
        </S.Menu>
        <S.Main>
          <S.SearchHeader>
            <form onSubmit={onHandleSearch}>
              <SecondarySearchInput
                value={term}
                onUpdateSearch={onUpdateSearchTerm}
                submitForm={ (e) => onHandleSearch(e) }
              />
            </form>
            <AuthButton />
          </S.SearchHeader>

          {results && results.errors && (
            <S.ErrorSnackBar>{results.errors}</S.ErrorSnackBar>
          )}

          {relationshipsActive && <RelationshipResults results={results} term={term} />}
          {dealsAndListsActive && <DealsResults results={results}  term={term} />}          
          {articlesActive && <ArticleResults results={results} term={term}/>}
          {webActive && <WebResults results={results} />}
          {documentsActive && <DocResults results={results} />}
        </S.Main>
      </S.Page>
      <Footer />
    </>
  );
};

export default Results;
