import styled from "styled-components";
import * as Colors from "../../styles/Colors";
export const Container = styled.div`
position: absolute;
top:0;
z-index: 100;
left:120px;
background: #fff;
min-width:760px;
max-width: 1000px;
border-bottom: 5px solid ${Colors.primary.yellow};
-webkit-box-shadow: 2px 2px 7px -1px rgba(0,0,0,0.5);
-moz-box-shadow: 2px 2px 7px -1px rgba(0,0,0,0.5);
box-shadow: 2px 2px 7px -1px rgba(0,0,0,0.5);

`;

export const Categories = styled.div`
padding: 0 20px;
`;

export const Row = styled.div`
display: flex;
flex-direction: row;
margin:0;
justify-content: center;
width: 100%;
`;

export const Col = styled.div`
display: flex;
flex-direction: column;
flex-basis: 100%;
flex: 1;
margin:0;
padding:0 20px;
`;

export const Exit = styled.div`
    display:flex;
    justify-content: flex-end;
    padding:12px;
    cursor:pointer;
    font-weight: bold;
`;