import React, { useState, useEffect, useContext } from "react";
import DataSources from "../DataSources/DataSources";
import RelationshipOptions from "../RelationshipsOptions/RelationshipsOptions";
import DocumentOptions from "../DocumentOptions/DocumentOptions";
import WebOptions from "../WebOptions/WebOptions";
import SearchContext from "../../SearchContext";
import * as S from "./RefinePanel.styles";
import _ from "lodash";
const RefinePanel = ({
  handleRefinementToggle,
  stateObj,
  handleSetStateObj,
}) => {
  const [fourDegreesActive, setFourDegreesActive] = useState(
    stateObj.fourdegrees ?? true
  );
  const [webActive, setWebActive] = useState(stateObj.web ?? true);

  const [dealsActive, setDealsActive] = useState(stateObj.deals ?? true);

  const [bloomFireActive, setBloomFireActive] = useState(
    stateObj.bloomfire ?? true
  );
  const [contactsActive, setContactsActive] = useState(
    stateObj.contacts ?? true
  );
  const [companiesActive, setCompaniesActive] = useState(
    stateObj.companies ?? true
  );
  const [wordDocActive, setWordDocActive] = useState(stateObj.docx ?? true);
  const [powerPointActive, setPowerPointActive] = useState(
    stateObj.pptx ?? true
  );
  const [excelActive, setExcelActive] = useState(stateObj.xlsx ?? true);
  const [pdfActive, setPDFActive] = useState(stateObj.pdf ?? true);
  const [openDocActive, setOpenDocActive] = useState(stateObj.odt ?? true);
  const [openPresActive, setOpenPresActive] = useState(stateObj.odp ?? true);
  const [openSheetActive, setOpenSheetActive] = useState(stateObj.ods ?? true);
  const [hgVenturesActive, setHGVenturesActive] = useState(
    stateObj.hgVentures ?? true
  );
  const [constructionActive, setConstructionActive] = useState(
    stateObj.construction ?? true
  );
  const [environmentalActive, setEnviromentalActive] = useState(
    stateObj.environmental ?? true
  );
  const [chemicalActive, setChemicalActive] = useState(
    stateObj.chemicals ?? true
  );
  const [thgCorpActive, setTHGCorpActive] = useState(
    stateObj.thgCorp ?? true
  );
  const [thgActive, setTHGActive] = useState(stateObj.thg ?? true);
  const [newsActive, setNewsActive] = useState(stateObj.news ?? true);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { term, setQueryParams } = useContext(SearchContext);

  const fourDegreesToggle = () => {
    // allow the panel to make queries. First load is to prevent the panel from performing a search when it is first shown to the user
    setIsFirstLoad(false);
    setFourDegreesActive(!fourDegreesActive);
  };
  const bloomFireToggle = () => {
    setIsFirstLoad(false);
    setBloomFireActive(!bloomFireActive);
  };
  const webToggle = () => {
    setIsFirstLoad(false);
    setWebActive(!webActive);
  };
  const handleDealsToggle = () => {
    setIsFirstLoad(false);
    setDealsActive(!dealsActive);
  }
  const contactsToggle = () => {
    setIsFirstLoad(false);
    setContactsActive(!contactsActive);
  };
  const companiesToggle = () => {
    setIsFirstLoad(false);
    setCompaniesActive(!companiesActive);
  };
  const wordDocToggle = () => {
    setIsFirstLoad(false);
    setWordDocActive(!wordDocActive);
  };
  const powerPointToggle = () => {
    setIsFirstLoad(false);
    setPowerPointActive(!powerPointActive);
  };
  const excelToggle = () => {
    setIsFirstLoad(false);
    setExcelActive(!excelActive);
  };
  const pdfToggle = () => {
    setIsFirstLoad(false);
    setPDFActive(!pdfActive);
  };
  const openDocToggle = () => {
    setIsFirstLoad(false);
    setOpenDocActive(!openDocActive);
  };
  const openPresToggle = () => {
    setIsFirstLoad(false);
    setOpenPresActive(!openPresActive);
  };
  const openSheetToggle = () => {
    setIsFirstLoad(false);
    setOpenSheetActive(!openSheetActive);
  };
  const hgVToggle = () => {
    setIsFirstLoad(false);
    setHGVenturesActive(!hgVenturesActive);
  };
  const constructionActiveToggle = () => {
    setIsFirstLoad(false);
    setConstructionActive(!constructionActive);
  };
  const environmentalActiveToggle = () => {
    setIsFirstLoad(false);
    setEnviromentalActive(!environmentalActive);
  };
  const chemicalToggle = () => {
    setIsFirstLoad(false);
    setChemicalActive(!chemicalActive);
  };
  const thgCorpToggle = () => {
    setIsFirstLoad(false);
    setTHGCorpActive(!thgCorpActive);
  };
  const thgToggle = () => {
    setIsFirstLoad(false);
    setTHGActive(!thgActive);
  };
  const newsToggle = () => {
    setIsFirstLoad(false);
    setNewsActive(!newsActive);
  };
  const exitButton = () => {
    handleRefinementToggle(false);
  };
  const getStateObj = () => {
    return {
      contacts: contactsActive,
      companies: companiesActive,
      hgVentures: hgVenturesActive,
      construction: constructionActive,
      news: newsActive,
      environmental: environmentalActive,
      chemicals: chemicalActive,
      thg: thgActive,
      thgCorp: thgCorpActive,
      groups: true,
      Categories: true,
      pdf: pdfActive,
      docx: wordDocActive,
      pptx: powerPointActive,
      xlsx: excelActive,
      odt: openDocActive,
      odp: openPresActive,
      ods: openSheetActive,
      web: webActive,
      deals: dealsActive,
      fourdegrees: fourDegreesActive,
      bloomfire: bloomFireActive,
    };
  };

  useEffect(() => {
    const obj = getStateObj();
    const stateObjChanged = _.isEqual(obj, stateObj);
    //Don't perform unnecessary queries. If the user if clicking to show the panel or if the query object hasn't changed, then do not perfrom a query.
    if (term && !stateObjChanged && !isFirstLoad) {
      setQueryParams(obj, term);
      handleSetStateObj(obj);
    }
    // eslint-disable-next-line
  }, [
    isFirstLoad,
    contactsActive,
    companiesActive,
    hgVenturesActive,
    constructionActive,
    newsActive,
    environmentalActive,
    chemicalActive,
    thgActive,
    thgCorpActive,
    pdfActive,
    wordDocActive,
    powerPointActive,
    excelActive,
    openDocActive,
    openPresActive,
    openSheetActive,
    webActive,
    dealsActive,
    fourDegreesActive,
    bloomFireActive,
  ]);
  return (
    <S.Container>
      <S.Exit onClick={exitButton}>X</S.Exit>
      <S.Row>
        <DataSources
          sources={{
            degrees: fourDegreesActive,
            bloomfire: bloomFireActive,
            web: webActive
          }}
          handleDegreesToggle={fourDegreesToggle}
          handleBloomfireToggle={bloomFireToggle}
          handleWebToggle={webToggle}
        />
      </S.Row>
      <S.Categories>
        <S.Row>
          <S.Col>
            <RelationshipOptions
              options={{ contacts: contactsActive, companies: companiesActive, deals: dealsActive }}
              handleContactsToggle={contactsToggle}
              handleCompaniesToggle={companiesToggle}
              handleDealsToggle={handleDealsToggle}
            />
          </S.Col>
          <S.Col>
            <DocumentOptions
              options={{
                word: wordDocActive,
                ppt: powerPointActive,
                xls: excelActive,
                pdf: pdfActive,
                openword: openDocActive,
                openppt: openPresActive,
                openxls: openSheetActive,
              }}
              handleWordToggle={wordDocToggle}
              handlePptToggle={powerPointToggle}
              handleXlsToggle={excelToggle}
              handlePdfToggle={pdfToggle}
              handleOpenWordToggle={openDocToggle}
              handleOpenPptToggle={openPresToggle}
              handleOpenXlsToggle={openSheetToggle}
            />
          </S.Col>
          <S.Col>
            <WebOptions
              options={{
                hgventures: hgVenturesActive,
                construction: constructionActive,
                environmental: environmentalActive,
                chemicals: chemicalActive,
                corporate: thgCorpActive,
                thg: thgActive,
                news: newsActive,
              }}
              handleVenturesToggle={hgVToggle}
              handleConstructionToggle={constructionActiveToggle}
              handleEnvironmentalToggle={environmentalActiveToggle}
              handleChemicalsToggle={chemicalToggle}
              handleCorporateToggle={thgCorpToggle}
              handleThgToggle={thgToggle}
              handleNewsToggle={newsToggle}
            />
          </S.Col>
        </S.Row>
      </S.Categories>
    </S.Container>
  );
};
export default RefinePanel;
